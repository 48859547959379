import React, { memo, useEffect, useState } from "react";
import withWindowSize from "@/components/higher-order/withWindowSize";
import { useParams } from "react-router-dom";
import KTMobileHeaderBar from "../../components/mobile-header-bar";
import { CategoryContentAllLocation, CategoryWrapper } from "./style";
import { categorysInfo } from "../../common/category/category-data";
import KTCategoryHeader from "./c-components/category-header";
import KTCategoryTitle from "./c-components/category-title";
import { getEventsOfCategory } from "../../services/events";
import KTMLyoutModeBar from "../../components/layout-mode/m-layout-mode-bar";
import { GLOBAL_PAGESIZE, LAYOUT_MODE_LIST, LAYOUT_MODE_CARD } from "../../common/constants";
import { shallowEqual, useSelector } from "react-redux";
import KTMobileEventList from "../../components/mobile-components/mobile-event-list";
import KTEventsListView from "@/components/event/events-list-view";
import KTWindowSizeContext from "../../common/context/windowSizeContext";
import KTAddressBar from "../../components/address-bar";
import KTEventLoadMore from "../../components/event/event-load-more";

const KTCategory = memo(({ windowSize }) => {
  const params = useParams();
  const category = params.category;
  const info = categorysInfo[category];
  console.log("category = ", category);
 if (category==='NBA') { 
  const newState = { page: 'sweepstake' }; 
  window.history.replaceState(newState,  
          'Redirect', '/#/sweepstake'); 
  window.location.replace('/#/sweepstake'); 
}; 



  const { city } = useSelector(
    (state) => ({
      city: state.getIn(["global", "city"]),
    }),
    shallowEqual
  );

  const [layoutMode, setLayouMode] = useState(LAYOUT_MODE_CARD);

  const [pagenum, setPageNum] = useState(1);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [events, setEvents] = useState([]);

  const [allPagenum, setAllPageNum] = useState(1);
  const [showAllLoadMore, setShowAllLoadMore] = useState(false);
  const [allEvents, setAllEvents] = useState([]);

  useEffect(() => {
    setPageNum(1);
    setAllPageNum(1);

    console.log("city: ", city);

    getEventsOfCategory(
      pagenum,
      city,
      info.category,
      info.subcategory,
      info.keywords,
      (eventResults) => {
        console.log("eventResults: ", eventResults);
        setShowLoadMore(eventResults?.length === GLOBAL_PAGESIZE);
        setEvents(eventResults);
      }
    );

    getEventsOfCategory(
      allPagenum,
      {},
      info.category,
      info.subcategory,
      info.keywords,
      (eventResults) => {
        console.log("all eventResults: ", eventResults);
        setShowAllLoadMore(eventResults?.length === GLOBAL_PAGESIZE);
        setAllEvents(eventResults);
      }
    );
  }, [info, city]);

  function onclickLoadMoreButton() {
    setPageNum(pagenum + 1);
    getEventsOfCategory(
      pagenum,
      city,
      info.category,
      info.subcategory,
      (events) => {
        setShowLoadMore(events?.length === GLOBAL_PAGESIZE);
        setEvents(events);
      }
    );
  }

  function onclickAllLoadMoreButton() {
    setAllPageNum(pagenum + 1);
    getEventsOfCategory(
      pagenum,
      {},
      info.category,
      info.subcategory,
      (events) => {
        setShowAllLoadMore(events?.length === GLOBAL_PAGESIZE);
        setAllEvents(events);
      }
    );
  }

  function allLocationEventsElements() {
    if (
      events.length > 10 ||
      city === null ||
      city === undefined ||
      Object.keys(city).length === 0
    ) {
      return [];
    }

    const elements = [
      <CategoryContentAllLocation>All Locations</CategoryContentAllLocation>,
    ];

    if (windowSize.width > 480) {
      elements.push(<KTEventsListView events={allEvents} />);
    } else {
      elements.push(
        <KTMobileEventList
          events={allEvents}
          layoutMode={layoutMode}
          noEventsPrompt={""}
        />
      );
    }

    if (showAllLoadMore) {
      elements.push(
        <KTEventLoadMore onLoadMoreClick={onclickAllLoadMoreButton} />
      );
    }
    return elements;
  }

  return (
    <KTWindowSizeContext.Provider value={{ windowSize: windowSize }}>
      <CategoryWrapper>
        {windowSize.width <= 480 ? <KTMobileHeaderBar /> : null}

        <KTCategoryHeader
          headerImageSrc={
            windowSize.width <= 480
              ? require(`@/assets/img/categorys/${info.headerImage}`)
              : require(`@/assets/img/categorys/desktop/${info.desktopHeader}`)
          }
        />
        <KTCategoryTitle title={info.title} />
        {windowSize.width > 480 ? (
          <KTAddressBar showNoEventsPrompt={events.length === 0} />
        ) : (
          <KTMLyoutModeBar
            layoutMode={layoutMode}
            didChangeLayoutMode={(newLayoutMode) => {
              setLayouMode(newLayoutMode);
            }}
            hiddenLayoutMode={true}
            showNoEventsPrompt={events.length === 0}
          />
        )}

        {events.length > 0 ? (
          windowSize.width > 480 ? (
            <KTEventsListView events={events} />
          ) : (
            <KTMobileEventList
              events={events}
              layoutMode={layoutMode}
              noEventsPrompt={""}
            />
          )
        ) : null}

        {showLoadMore ? (
          <KTEventLoadMore onLoadMoreClick={onclickLoadMoreButton} />
        ) : null}
        {events.length===0 ? 
        allLocationEventsElements(): null }
      </CategoryWrapper>
    </KTWindowSizeContext.Provider>
  );
});

export default withWindowSize(KTCategory);
