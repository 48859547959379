import React, { memo } from "react";

import { HomeHeaderCarouselWrapper } from "./style";
import KTPromoCard from "@/components/promo-card";
import withWindowSize from "@/components/higher-order/withWindowSize";
import { ReactComponent as ArrowPrve } from "@/assets/img/icons/icon-carousel-arrow-left.svg";
import { ReactComponent as ArrowNext } from "@/assets/img/icons/icon-carousel-arrow-right.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";
import { middleCategorys } from "../../../../../common/category/category-data";
SwiperCore.use([Navigation]);

const KTHomeHeaderCarousel = memo(({ windowSize }) => {
  function getCardSize(windowSize) {
    const windowWidth = windowSize.width;
    if (windowWidth >= 1920) {
      return {
        isSmall: false,
        width: "333px",
        height: "200px",
      };
    } else if (windowWidth <= 1024 && windowWidth >= 602) {
      return {
        isSmall: false,
        width: "233px",
        height: "140px",
      };
    } else if (windowWidth < 602) {
      return {
        isSmall: true,
        width: "164px",
        height: "140px",
      };
    } else {
      const windowRang = 1920 - 1024;
      const heightRang = 60;
      const value = windowWidth - 1024;
      const height = (value / windowRang) * heightRang + 140;
      const width = height * (333.0 / 200.0);

      return { width: `${width}px`, height: `${height}px`, isSmall: false };
    }
  }

  const { width, height, isSmall } = getCardSize(windowSize);

  function getMarkSize(windowSize) {
    const windowWidth = windowSize.width;
    if (windowWidth >= 1920) {
      return {
        markWidth: "150px",
        markHeight: "200px",
      };
    } else if (windowWidth <= 1024) {
      return {
        markWidth: "100px",
        markHeight: "150px",
      };
    } else {
      const windowRang = 1920 - 1024;
      const heightRang = 50;
      const value = windowWidth - 1024;
      const height = (value / windowRang) * heightRang + 150;
      const width = height * (3 / 4);

      return { markWidth: `${width}px`, markHeight: `${height}px` };
    }
  }

  const { markWidth, markHeight } = getMarkSize(windowSize);

  function getPrevNextSize(windowSize) {
    const windowWidth = windowSize.width;
    if (windowWidth >= 1920) {
      return {
        buttonSize: "44px",
        buttonTop: "110px",
      };
    } else if (windowWidth <= 1024) {
      return {
        buttonSize: "34px",
        buttonTop: "92px",
      };
    } else {
      const windowRang = 1920 - 1024;
      const value = windowWidth - 1024;
      const size = (value / windowRang) * 10 + 34;

      const buttonTop = (value / windowRang) * 18 + 92;

      return { buttonSize: `${size}px`, buttonTop: `${buttonTop}px` };
    }
  }

  const { buttonSize, buttonTop } = getPrevNextSize(windowSize);

  const cards = middleCategorys.map((item) => {
    return (
      <SwiperSlide
        key={item.name}
        style={{
          width: width,
          height: height,
        }}
      >
        <KTPromoCard
          item={item}
          itemWidth={width}
          itemHeight={height}
          isSmall={isSmall}
        />
      </SwiperSlide>
    );
  });

  const decorateItems =
    windowSize.width > 480
      ? [
          <img
            className="leftMask"
            src={require("@/assets/img/home/img-leftmask.png")}
            alt=""
            style={{
              width: markWidth,
              height: markHeight,
            }}
          />,

          <img
            className="rightMask"
            src={require("@/assets/img/home/img-rightmask.png")}
            alt=""
            style={{
              width: markWidth,
              height: markHeight,
            }}
          />,

          <ArrowPrve
            className="swiperButtonPrev"
            width={buttonSize}
            height={buttonSize}
            style={{
              top: buttonTop,
            }}
            onClick={() => {
              if (swiper) {
                swiper.slidePrev();
              }
            }}
          />,
          <ArrowNext
            className="swiperButtonNext"
            width={buttonSize}
            height={buttonSize}
            style={{
              top: buttonTop,
            }}
            onClick={() => {
              if (swiper) {
                swiper.slideNext();
              }
            }}
          />,
        ]
      : [];

  let swiper;

  return (
    <HomeHeaderCarouselWrapper>
      <Swiper
        className="swiper"
        spaceBetween={16}
        slidesPerView={"auto"}
        onInit={(s) => (swiper = s)}
        onResize={(s) => (swiper = s)}
      >
        {cards}
      </Swiper>
      {decorateItems}
    </HomeHeaderCarouselWrapper>
  );
});

export default withWindowSize(KTHomeHeaderCarousel);
